import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';

/**
 * @param {*} imageInfo = {
 *  alt?
 *  childImageSharp?
 *  image
 *  aspectRatio?
 * }
 */
const PreviewCompatibleImage = ({ imageInfo }) => {
  const imageStyle = { width: 'auto', height: 'auto' };
  const { alt = '', childImageSharp, image, aspectRatio } = imageInfo;

  // svg support
  if (!!image && !image.childImageSharp && image.extension === 'svg') {
    return <img style={imageStyle} src={image.publicURL} alt={alt} />;
  }

  if (!!image && !!image.childImageSharp) {
    let fluid = image.childImageSharp.fluid;
    if (aspectRatio) {
      fluid = { ...fluid, aspectRatio: aspectRatio };
    }

    return (
      <Img className="is-4by3" style={imageStyle} fluid={fluid} alt={alt} />
    );
  }

  if (!!childImageSharp) {
    let fluid = childImageSharp.fluid;
    // if (aspectRatio) {
    //   fluid = { ...fluid, aspectRatio: aspectRatio };
    // }

    return <Img style={imageStyle} fluid={fluid} alt={alt} />;
  }

  if (!!image && typeof image === 'string')
    return <img style={imageStyle} src={image} alt={alt} />;

  return null;
};

PreviewCompatibleImage.propTypes = {
  imageInfo: PropTypes.shape({
    alt: PropTypes.string,
    childImageSharp: PropTypes.object,
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
    style: PropTypes.object,
  }).isRequired,
};

export default PreviewCompatibleImage;
